<template>
    <div class="box">
        <b-loading v-model="isLoading" :is-full-page="true" />

        <section
            v-if="!Object.keys(preorders).length && !isLoading"
            class="notification has-background-warning-light text-center mb-4"
        >
            <p class="mb-0">
                {{ $t('partenaire-precommandes.nopreorder') }}
            </p>
        </section>

        <section v-else>
            <PartnerPreOrdersTable
                :preorders-list="preorders"
                :groups="groups"
                @loadAsyncData="loadAsyncData"
                @showExportModal="showExportModal"
                @newInterest="newInterest"
                @updateInterest="updateInterest"
                @deleteInterest="deleteInterest"
                @deleteAllInterest="deleteAllInterest"
            />

            <nl-modal :open-modal="showModal" title="Export" @hidden="closeModal">
                <div class="content d-flex flex-column">
                    <b-loading v-model="exportLoading" :is-full-page="false" />

                    <div v-if="!exportLink" class="mx-auto my-4">
                        <p>
                            {{ $t('partenaire-commandes.choose-format') }}
                        </p>

                        <div class="form-group">
                            <select id="format" v-model="exportFormat" class="form-control" name="format">
                                <option value="csv">CSV</option>
                                <option value="xlsx">XLSX</option>
                            </select>
                        </div>

                        <button class="button is-warning" @click="exportPreOrders">
                            {{ $t('partenaire-commandes.get-data') }}
                        </button>
                    </div>

                    <div v-else-if="!isLoading && exportLink" class="mx-auto my-4">
                        <button class="button is-warning" @click="download">
                            {{ $t('global.download') }}
                        </button>
                    </div>
                </div>
            </nl-modal>
        </section>
    </div>
</template>

<script>
    export default {
        title: 'layout.customerneeds',
        data() {
            return {
                preorders: {},
                groups: [],
                isLoading: false,

                exportFormat: 'csv',
                exportLink: null,
                showModal: false,
                exportLoading: false
            }
        },

        created() {
            this.getData()
        },

        methods: {
            getData() {
                this.isLoading = true

                this.axios
                    .get(`/partner-preorders`)
                    .then(response => {
                        this.preorders = response.data.preorders
                        this.groups = response.data.groups
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.isLoading = false))
            },

            newInterest(params) {
                this.isLoading = true

                this.axios
                    .post(`/partner-preorders/interest`, {
                        preorder_id: params.preorder.id,
                        url: params.url
                    })
                    .then(response => {
                        params.callback(response.data.interest)
                        this.snackNotif(this.$t('partenaire-precommandes.interest-considered'), 'is-success')
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(this.$t('global.new-error'), 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            updateInterest(params) {
                this.isLoading = true

                this.axios
                    .post(`/partner-preorders/interest/${params.interest.id}`, {
                        url: params.interest.url,
                        site_id: params.site_id,
                        _method: 'PATCH'
                    })
                    .then(response => {
                        params.callback(response.data.interest)
                        this.snackNotif(this.$t('partenaire-precommandes.interest-considered'), 'is-success')
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(this.$t('global.new-error'), 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            deleteInterest(params) {
                this.isLoading = true

                this.axios
                    .post(`/partner-preorders/interest/${params.interest.id}`, {
                        _method: 'DELETE'
                    })
                    .then(response => {
                        params.callback(response.data.interest_id)
                        this.snackNotif(this.$t('partenaire-precommandes.interest-considered'), 'is-success')
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(this.$t('global.new-error'), 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            deleteAllInterest(params) {
                this.isLoading = true

                this.axios
                    .post(`/partner-preorders/${params.preorder.id}/interest`, {
                        _method: 'DELETE'
                    })
                    .then(() => {
                        params.callback()
                        this.snackNotif(this.$t('partenaire-precommandes.interest-considered'), 'is-success')
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(this.$t('global.new-error'), 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            exportPreOrders() {
                this.exportLoading = true

                this.axios
                    .get(`/partner-preorders/export?format=${this.exportFormat}`)
                    .then(response => {
                        this.exportLink = response.data.download_link
                    })
                    .catch(error => this.snackNotif(error.response.data.message, 'is-danger'))
                    .finally(() => (this.exportLoading = false))
            },

            download() {
                if (!this.exportLink) {
                    return
                }

                window.location.href = this.exportLink
            },

            showExportModal() {
                this.showModal = true
            },

            closeModal() {
                this.showModal = false
            },

            // temp
            snackNotif(message, type = 'is-success', duration = 5000) {
                this.$buefy.snackbar.open({
                    message: message,
                    type: type,
                    position: 'is-bottom',
                    duration: duration
                })
            },

            loadAsyncData(data) {
                var field = data.sortField
                var route

                if (data.sortOrder === 'desc') {
                    field = '-' + data.sortField
                }

                var params = {
                    page: data.page,
                    include: data.includes,
                    sort: field
                }

                if (data.filters && data.filters.length > 0) {
                    data.filters.forEach(element => {
                        params[`filter[${element.key}]`] = element.value
                    })
                }

                route = data.path

                let perPage = data.per_page ? data.per_page : 20
                perPage = route.includes('status') ? `&per_page=${perPage}` : `?per_page=${perPage}`

                this.axios
                    .get(route + perPage, {
                        params
                    })
                    .then(response => {
                        this.preorders = response.data.preorders
                    })
                    .catch(error => {
                        this.snackNotif(error.response.data.message, 'is-danger')
                        console.error(error)
                    })
                    .finally(() => {
                        data.callback()
                    })
            }
        }
    }
</script>
